import { createStore, combineReducers } from 'redux';

import layersReducer from './Ducks/Layers';
import featuresReducer from './Ducks/Features';

const rootReducer = combineReducers({
  layers: layersReducer,
  features: featuresReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer);

export default store;
