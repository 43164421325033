import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PixiOverlay } from 'react-leaflet-pixi-overlay';
import { renderToString } from 'react-dom/server';

import { Api } from '~/Services';
import { RootState } from '~/Redux';
import { loadVPs } from '~/Redux/Ducks/Features';

interface MapVPsProps {
  color: string;
}

const MapVPs: React.FC<MapVPsProps> = (props) => {
  const { color } = props;

  const dispatch = useDispatch();
  const { vps: vpsState } = useSelector((state: RootState) => state.features);

  const [vps, setVPs] = useState<any[]>([]);

  useEffect(() => {
    Api.post('/graphql', {
      query: `
        query PostForFixes {
          VisualPositions {
            id
            ident
            rea
            geolocation {
              lat
              lng
            }
          }
        }`,
    }).then((result: any) => {
      const rawVPs = result.data.data.VisualPositions;
      const parsedVPs = rawVPs.map((rawVP: any, index: number) => {
        return {
          id: `fix-marker-${rawVP.ident}-${index}`,
          iconColor: color,
          position: [rawVP.geolocation.lat, rawVP.geolocation.lng],
          tooltip: renderToString(
            <div>
              {rawVP.ident}
              <br />
              {rawVP.rea}
            </div>
          ),
        };
      });
      setVPs(parsedVPs);
      dispatch(loadVPs());
    });
  }, [dispatch, color]);

  return vpsState.active ? <PixiOverlay markers={vps} /> : null;
};

export default MapVPs;
