import React, { useState } from 'react';
import { Map as LeafletMap, Marker, Popup } from 'react-leaflet';
import { LatLng } from 'leaflet';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import MapLayers from './Layers';
import MapFeatures from './Features';

import './styles.css';

const INITIAL_LATITUDE = -22;
const INITIAL_LONGITUDE = -45;
const INITIAL_ZOOM = 7;

interface MapProps {}

const Map: React.FC<MapProps> = () => {
  // eslint-disable-next-line
  const [position, setPosition] = useState<[number, number]>([INITIAL_LATITUDE, INITIAL_LONGITUDE]);
  // eslint-disable-next-line
  const [zoom, setZoom] = useState(INITIAL_ZOOM);

  const [selectedPostion, setSelectedPosition] = useState<LatLng | null>(null);

  const initMarker = (ref: any) => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };

  return (
    <LeafletMap center={position} zoom={zoom} onclick={(e) => setSelectedPosition(e.latlng)}>
      <MapLayers />
      <MapFeatures />

      {selectedPostion && (
        <Marker ref={initMarker} position={selectedPostion}>
          <Popup
            direction="top"
            offset={[0, -13]}
            interactive
            permanent
            style={{ pointerEvents: 'auto' }}
          >
            Lat: <strong>{selectedPostion.lat.toFixed(8)}</strong>
            <br />
            Lng: <strong>{selectedPostion.lng.toFixed(8)}</strong>
            <br />
            <CopyToClipboard
              text={`${selectedPostion.lat.toFixed(8)},${selectedPostion.lng.toFixed(8)}`}
            >
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                Copiar
              </button>
            </CopyToClipboard>
          </Popup>
        </Marker>
      )}
    </LeafletMap>
  );
};

export default Map;
