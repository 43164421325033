import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PixiOverlay } from 'react-leaflet-pixi-overlay';
import { renderToString } from 'react-dom/server';

import { Api } from '~/Services';
import { RootState } from '~/Redux';
import { loadCities } from '~/Redux/Ducks/Features';

interface MapCitiesProps {
  color: string;
}

const MapCities: React.FC<MapCitiesProps> = (props) => {
  const { color } = props;

  const dispatch = useDispatch();
  const { cities: citiesState } = useSelector((state: RootState) => state.features);

  const [cities, setCities] = useState<any[]>([]);

  useEffect(() => {
    Api.post('/graphql', {
      query: `
        query PostForCities {
          Cities {
            id
            fullName
            geolocation {
              lat
              lng
            }
          }
        }`,
    }).then((result: any) => {
      const rawCities = result.data.data.Cities;
      const parsedCities = rawCities.map((rawCity: any, index: number) => {
        return {
          id: `navaid-marker-${rawCity.id}-${index}`,
          iconColor: color,
          position: [rawCity.geolocation.lat, rawCity.geolocation.lng],
          tooltip: renderToString(<div>{rawCity.fullName}</div>),
        };
      });
      setCities(parsedCities);
      dispatch(loadCities());
    });
  }, [dispatch, color]);

  return citiesState.active ? <PixiOverlay markers={cities} /> : null;
};

export default MapCities;
