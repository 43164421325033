import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PixiOverlay } from 'react-leaflet-pixi-overlay';
import { renderToString } from 'react-dom/server';

import { Api } from '~/Services';
import { RootState } from '~/Redux';
import { loadFixes } from '~/Redux/Ducks/Features';

interface MapFixesProps {
  color: string;
}

const MapFixes: React.FC<MapFixesProps> = (props) => {
  const { color } = props;

  const dispatch = useDispatch();
  const { fixes: fixesState } = useSelector((state: RootState) => state.features);

  const [fixes, setFixes] = useState<any[]>([]);

  useEffect(() => {
    Api.post('/graphql', {
      query: `
        query PostForFixes {
          Waypoints {
            ident
            geolocation {
              lat
              lng
            }
          }
        }`,
    }).then((result: any) => {
      const rawFixes = result.data.data.Waypoints;
      const parsedFixes = rawFixes.map((rawFix: any, index: number) => {
        return {
          id: `fix-marker-${rawFix.ident}-${index}`,
          iconColor: color,
          position: [rawFix.geolocation.lat, rawFix.geolocation.lng],
          tooltip: renderToString(<div>{rawFix.ident}</div>),
        };
      });
      setFixes(parsedFixes);
      dispatch(loadFixes());
    });
  }, [dispatch, color]);

  return fixesState.active ? <PixiOverlay markers={fixes} /> : null;
};

export default MapFixes;
