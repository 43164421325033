const Actions = {
  TOGGLE_AIRSPACES: 'features/TOGGLE_AIRSPACES',
  TOGGLE_AERODROMES: 'features/TOGGLE_AERODROMES',
  TOGGLE_NAVAIDS: 'features/TOGGLE_NAVAIDS',
  TOGGLE_FIXES: 'features/TOGGLE_FIXES',
  TOGGLE_CITIES: 'features/TOGGLE_CITIES',
  TOGGLE_VPS: 'features/TOGGLE_VPS',

  LOAD_AIRSPACES: 'features/LOAD_AIRSPACES',
  LOAD_AERODROMES: 'features/LOAD_AERODROMES',
  LOAD_NAVAIDS: 'features/LOAD_NAVAIDS',
  LOAD_FIXES: 'features/LOAD_FIXES',
  LOAD_CITIES: 'features/LOAD_CITIES',
  LOAD_VPS: 'features/LOAD_VPS',
};

interface FeatureStatus {
  active: boolean;
  loading: boolean;
}

interface Features {
  airspaces: FeatureStatus;
  aerodromes: FeatureStatus;
  navaids: FeatureStatus;
  fixes: FeatureStatus;
  cities: FeatureStatus;
  vps: FeatureStatus;
}

export type State = Features;

const initialState: State = {
  airspaces: {
    active: false,
    loading: true,
  },
  aerodromes: {
    active: false,
    loading: true,
  },
  navaids: {
    active: false,
    loading: true,
  },
  fixes: {
    active: false,
    loading: true,
  },
  cities: {
    active: false,
    loading: true,
  },
  vps: {
    active: false,
    loading: true,
  },
};

type ActionType = {
  type: string;
};

export const toggleAirspaces = (): ActionType => ({
  type: Actions.TOGGLE_AIRSPACES,
});

export const toggleAerodromes = (): ActionType => ({
  type: Actions.TOGGLE_AERODROMES,
});

export const toggleNavaids = (): ActionType => ({
  type: Actions.TOGGLE_NAVAIDS,
});

export const toggleFixes = (): ActionType => ({
  type: Actions.TOGGLE_FIXES,
});

export const toggleCities = (): ActionType => ({
  type: Actions.TOGGLE_CITIES,
});

export const toggleVPs = (): ActionType => ({
  type: Actions.TOGGLE_VPS,
});

export const loadAirspaces = (): ActionType => ({
  type: Actions.LOAD_AIRSPACES,
});

export const loadAerodromes = (): ActionType => ({
  type: Actions.LOAD_AERODROMES,
});

export const loadNavaids = (): ActionType => ({
  type: Actions.LOAD_NAVAIDS,
});

export const loadFixes = (): ActionType => ({
  type: Actions.LOAD_FIXES,
});

export const loadCities = (): ActionType => ({
  type: Actions.LOAD_CITIES,
});

export const loadVPs = (): ActionType => ({
  type: Actions.LOAD_VPS,
});

const reducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case Actions.TOGGLE_AIRSPACES:
      return { ...state, airspaces: { ...state.airspaces, active: !state.airspaces.active } };
    case Actions.TOGGLE_AERODROMES:
      return { ...state, aerodromes: { ...state.aerodromes, active: !state.aerodromes.active } };
    case Actions.TOGGLE_NAVAIDS:
      return { ...state, navaids: { ...state.navaids, active: !state.navaids.active } };
    case Actions.TOGGLE_FIXES:
      return { ...state, fixes: { ...state.fixes, active: !state.fixes.active } };
    case Actions.TOGGLE_CITIES:
      return { ...state, cities: { ...state.cities, active: !state.cities.active } };
    case Actions.TOGGLE_VPS:
      return { ...state, vps: { ...state.vps, active: !state.vps.active } };

    case Actions.LOAD_AIRSPACES: {
      return { ...state, airspaces: { ...state.airspaces, loading: false } };
    }
    case Actions.LOAD_AERODROMES: {
      return { ...state, aerodromes: { ...state.aerodromes, loading: false } };
    }
    case Actions.LOAD_NAVAIDS: {
      return { ...state, navaids: { ...state.navaids, loading: false } };
    }
    case Actions.LOAD_FIXES: {
      return { ...state, fixes: { ...state.fixes, loading: false } };
    }
    case Actions.LOAD_CITIES: {
      return { ...state, cities: { ...state.cities, loading: false } };
    }
    case Actions.LOAD_VPS: {
      return { ...state, vps: { ...state.vps, loading: false } };
    }
    default:
      return state;
  }
};

export default reducer;
