import React from 'react';

import Map from './Components/Map';
import ToolBar from './Components/ToolBar';

import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="titleContainer">
        <span className="title">NexAtlas Workbench</span>
      </div>
      <ToolBar className="toolbar__container" />
      <Map />
    </div>
  );
};

export default App;
