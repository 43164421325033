import React from 'react';
import { useSelector } from 'react-redux';
import { TileLayer, LayersControl } from 'react-leaflet';

import { RootState } from '~/Redux';

const { BaseLayer, Overlay } = LayersControl;

interface MapLayersProps {}

const MapLayers: React.FC<MapLayersProps> = () => {
  const { wac, enrcHigh, enrcLow, rea, reh, arc } = useSelector((state: RootState) => state.layers);

  return (
    <>
      <LayersControl>
        <BaseLayer checked={wac} name="WAC">
          <TileLayer
            attribution='&amp;copy <a href="https://www.aisweb.aer.mil.br/">AISWeb</a>'
            url="https://{s}.tiles.nexatlas.com/wac/latest/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer checked={enrcHigh} name="ENRC High">
          <TileLayer
            attribution='&amp;copy <a href="https://www.aisweb.aer.mil.br/">AISWeb</a>'
            url="https://{s}.tiles.nexatlas.com/enrch/latest/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer checked={enrcLow} name="ENRC Low">
          <TileLayer
            attribution='&amp;copy <a href="https://www.aisweb.aer.mil.br/">AISWeb</a>'
            url="https://{s}.tiles.nexatlas.com/enrcl/latest/{z}/{x}/{y}.png"
          />
        </BaseLayer>

        <Overlay checked={rea} name="REA">
          <TileLayer
            attribution='&amp;copy <a href="https://www.aisweb.aer.mil.br/">AISWeb</a>'
            url="https://{s}.tiles.nexatlas.com/rea/latest/{z}/{x}/{y}.png"
          />
        </Overlay>
        <Overlay checked={reh} name="REH">
          <TileLayer
            attribution='&amp;copy <a href="https://www.aisweb.aer.mil.br/">AISWeb</a>'
            url="https://{s}.tiles.nexatlas.com/reh/latest/{z}/{x}/{y}.png"
          />
        </Overlay>
        <Overlay checked={arc} name="ARC">
          <TileLayer
            attribution='&amp;copy <a href="https://www.aisweb.aer.mil.br/">AISWeb</a>'
            url="https://{s}.tiles.nexatlas.com/arc/latest/{z}/{x}/{y}.png"
          />
        </Overlay>
      </LayersControl>
    </>
  );
};

export default MapLayers;
