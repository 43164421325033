import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PixiOverlay } from 'react-leaflet-pixi-overlay';
import { renderToString } from 'react-dom/server';

import { Api } from '~/Services';
import { RootState } from '~/Redux';
import { loadNavaids } from '~/Redux/Ducks/Features';

interface MapNavaidsProps {
  color: string;
}

const MapNavaids: React.FC<MapNavaidsProps> = (props) => {
  const { color } = props;

  const dispatch = useDispatch();
  const { navaids: navaidsState } = useSelector((state: RootState) => state.features);

  const [navaids, setNavaids] = useState<any[]>([]);

  useEffect(() => {
    Api.post('/graphql', {
      query: `
        query PostForNavaids {
          Navaids {
            ident
            name
            geolocation {
              lat
              lng
            }
          }
        }`,
    }).then((result: any) => {
      const rawNavaids = result.data.data.Navaids;
      const parsedNavaids = rawNavaids.map((rawNavaid: any, index: number) => {
        return {
          id: `navaid-marker-${rawNavaid.ident}-${index}`,
          iconColor: color,
          position: [rawNavaid.geolocation.lat, rawNavaid.geolocation.lng],
          tooltip: renderToString(
            <div>
              {rawNavaid.ident}
              <br />
              {rawNavaid.name}
            </div>
          ),
        };
      });
      setNavaids(parsedNavaids);
      dispatch(loadNavaids());
    });
  }, [dispatch, color]);

  return navaidsState.active ? <PixiOverlay markers={navaids} /> : null;
};

export default MapNavaids;
