const Actions = {
  TOGGLE_WAC: 'WAC',
  TOGGLE_ENRC_HIGH: 'ENRC_HIGH',
  TOGGLE_ENRC_LOW: 'ENRC_LOW',

  TOGGLE_REA: 'REA',
  TOGGLE_REH: 'REH',
  TOGGLE_ARC: 'ARC',
};

interface Layers {
  wac: boolean;
  enrcHigh: boolean;
  enrcLow: boolean;

  rea: boolean;
  reh: boolean;
  arc: boolean;
}

export type State = Layers;

const initialState: State = {
  wac: true,
  enrcHigh: false,
  enrcLow: false,

  rea: false,
  reh: false,
  arc: false,
};

type ActionType = {
  type: string;
};

export const toggleWac = (): ActionType => ({
  type: Actions.TOGGLE_WAC,
});

export const toggleEnrcHigh = (): ActionType => ({
  type: Actions.TOGGLE_ENRC_HIGH,
});

export const toggleEnrcLow = (): ActionType => ({
  type: Actions.TOGGLE_ENRC_LOW,
});

export const toggleRea = (): ActionType => ({
  type: Actions.TOGGLE_REA,
});

export const toggleReh = (): ActionType => ({
  type: Actions.TOGGLE_REH,
});

export const toggleArc = (): ActionType => ({
  type: Actions.TOGGLE_ARC,
});

const reducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case Actions.TOGGLE_WAC:
      return { ...state, wac: !state.wac };
    case Actions.TOGGLE_ENRC_HIGH:
      return { ...state, enrcHigh: !state.enrcHigh };
    case Actions.TOGGLE_ENRC_LOW:
      return { ...state, enrcLow: !state.enrcLow };
    case Actions.TOGGLE_REA:
      return { ...state, rea: !state.rea };
    case Actions.TOGGLE_REH:
      return { ...state, reh: !state.reh };
      case Actions.TOGGLE_ARC:
      return { ...state, arc: !state.arc };
    default:
      return state;
  }
};

export default reducer;
