import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeoJSON } from 'react-leaflet';

import { Api } from '~/Services';
import { RootState } from '~/Redux';
import { loadAirspaces } from '~/Redux/Ducks/Features';

interface MapAirspacesProps {}

const MapAirspaces: React.FC<MapAirspacesProps> = () => {
  const dispatch = useDispatch();
  const { airspaces: airspacesState } = useSelector((state: RootState) => state.features);

  const [airspaces, setAirspaces] = useState<any[]>([]);

  useEffect(() => {
    Api.get('/airspaces').then((value) => {
      const rawApiAirspaces = value.data.airspaces;
      const apiAirspaces = rawApiAirspaces.map((rawAirspace: any) => {
        return rawAirspace.geometries.map((geometry: any) => {
          return geometry?.horizontalProjection;
        });
      });

      setAirspaces(apiAirspaces);
      dispatch(loadAirspaces());
    });
  }, [dispatch]);

  const airspacesComponents = useMemo(
    () => airspaces.map((airspace, index) => <GeoJSON data={airspace} key={`airspace-${index}`} />),
    [airspaces]
  );

  return <>{airspacesState.active && airspacesComponents}</>;
};

export default MapAirspaces;
