import React from 'react';

import LoadingGif from '~/Assets/loading.gif';

import './styles.css';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  active: boolean;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { active, disabled, children, ...otherProps } = props;

  return (
    <button
      type="button"
      className={`button ${active ? 'button__active' : ''} ${disabled ? 'button__disabled' : ''}`}
      disabled={disabled}
      {...otherProps}
    >
      {disabled && (
        <div className="button__loading">
          <img src={LoadingGif} alt="loading" />
        </div>
      )}
      {children}
    </button>
  );
};

export default Button;
