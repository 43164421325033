import React from 'react';

import MapAirspaces from './Airspaces';
import MapAerodromes from './Aerodromes';
import MapNavaids from './Navaids';
import MapFixes from './Fixes';
import MapCities from './Cities';
import MapVPs from './VPs';

interface MapFeaturesProps {}

const MapFeatures: React.FC<MapFeaturesProps> = () => {
  return (
    <>
      <MapAirspaces />
      <MapAerodromes color="red" />
      <MapNavaids color="green" />
      <MapFixes color="blue" />
      <MapCities color="orange" />
      <MapVPs color="cyan" />
    </>
  );
};

export default MapFeatures;
