import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PixiOverlay } from 'react-leaflet-pixi-overlay';
import { renderToString } from 'react-dom/server';

import { Api } from '~/Services';
import { RootState } from '~/Redux';
import { loadAerodromes } from '~/Redux/Ducks/Features';

interface MapAerodromesProps {
  color: string;
}

const MapAerodromes: React.FC<MapAerodromesProps> = (props) => {
  const { color } = props;

  const dispatch = useDispatch();
  const { aerodromes: aerodromesState } = useSelector((state: RootState) => state.features);

  const [aerodromes, setAerodromes] = useState<any[]>([]);

  useEffect(() => {
    Api.get('/waypoints').then((result: any) => {
      const rawAerodromes = result.data.waypoints;
      const filteredAerodromes = rawAerodromes.filter((rawAerodrome: any) => rawAerodrome[5] === 1);
      const parsedAerodromes = filteredAerodromes.map((filteredAerodrome: any, index: number) => {
        return {
          id: `aerodrome-marker-${filteredAerodrome[1]}-${index}`,
          iconColor: color,
          position: [filteredAerodrome[3], filteredAerodrome[4]],
          tooltip: renderToString(
            <div>
              {filteredAerodrome[1]}
              <br />
              {filteredAerodrome[2]}
            </div>
          ),
        };
      });
      setAerodromes(parsedAerodromes);
      dispatch(loadAerodromes());
    });
  }, [dispatch, color]);

  return aerodromesState.active ? <PixiOverlay markers={aerodromes} /> : null;
};

export default MapAerodromes;
