import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../Button';

import { RootState } from '~/Redux';
import {
  toggleAirspaces,
  toggleAerodromes,
  toggleNavaids,
  toggleFixes,
  toggleCities,
  toggleVPs,
} from '~/Redux/Ducks/Features';

import './styles.css';

interface ToolBarProps {
  className?: string;
}

const ToolBar: React.FC<ToolBarProps> = (props) => {
  const { className } = props;

  const dispatch = useDispatch();
  const { airspaces, aerodromes, navaids, fixes, cities, vps } = useSelector(
    (state: RootState) => state.features
  );

  const handleAirspacesClick = () => {
    dispatch(toggleAirspaces());
  };

  const handleAerodromesClick = () => {
    dispatch(toggleAerodromes());
  };

  const handleNavaidsClick = () => {
    dispatch(toggleNavaids());
  };

  const handleFixesClick = () => {
    dispatch(toggleFixes());
  };

  const handleCitiesClick = () => {
    dispatch(toggleCities());
  };

  const handleVPsClick = () => {
    dispatch(toggleVPs());
  };

  return (
    <div className={`toolbar ${className}`}>
      <Button onClick={handleAirspacesClick} active={airspaces.active} disabled={airspaces.loading}>
        Espaços Aéreos
      </Button>
      <Button
        onClick={handleAerodromesClick}
        active={aerodromes.active}
        disabled={aerodromes.loading}
      >
        Aeródromos
      </Button>
      <Button onClick={handleNavaidsClick} active={navaids.active} disabled={navaids.loading}>
        Navaids
      </Button>
      <Button onClick={handleFixesClick} active={fixes.active} disabled={fixes.loading}>
        Fixos
      </Button>
      <Button onClick={handleCitiesClick} active={cities.active} disabled={cities.loading}>
        Cidades
      </Button>
      <Button onClick={handleVPsClick} active={vps.active} disabled={vps.loading}>
        Posições Visuais
      </Button>
    </div>
  );
};

export default ToolBar;
